import { useCallback, useEffect, useState } from "react"
import { useParams, useLocation, Navigate } from "react-router-dom"
import { useSignIn } from "react-auth-kit"
import queryString from "query-string"
import { validateToken } from "utils/tokenHelpers"
import * as API from "api"
import { decodeTokenToUser } from "utils/user_tenant_helpers"
import jwtDecode, { JwtPayload } from "jwt-decode"
import { refreshTokenExpiresIn } from "utils/tokenHelpers"
import { LinkType } from "entities/enums"

interface IOrderAuthProps {
  children: JSX.Element
}

const OrderAuth = ({ children }: IOrderAuthProps) => {
  const signIn = useSignIn()
  const { search } = useLocation()
  const { prospectId } = useParams()
  const isEdit = useLocation().pathname.includes("edit")
  const linkType = isEdit ? LinkType.Edit : LinkType.Order
  const redirectUrl = `/register/${prospectId}/${linkType}${search}`
  const [orderAuthCompleted, setOrderAuthCompleted] = useState<boolean | null>(null)

  const handleSignIn = useCallback(async () => {
    const query = queryString.parse(search)
    const token = localStorage.getItem("token")
    if (validateToken(token)) {
      setOrderAuthCompleted(true)
      return
    }
    if (query.token) {
      const magicTokenResponse = await API.Token.postMagicToken({ magicToken: query.token })

      if (magicTokenResponse.data.success) {
        const user = decodeTokenToUser(magicTokenResponse.data.token)
        const tokenExpiresIn = jwtDecode<JwtPayload>(magicTokenResponse.data.token).exp!

        if (
          signIn({
            token: magicTokenResponse.data.token,
            expiresIn: tokenExpiresIn,
            tokenType: "Bearer",
            authState: user,
            refreshToken: magicTokenResponse.data.refreshToken,
            refreshTokenExpireIn: refreshTokenExpiresIn,
          })
        ) {
          setOrderAuthCompleted(true)
        } else {
          setOrderAuthCompleted(false)
        }
      } else {
        setOrderAuthCompleted(false)
      }
    }
  }, [signIn])

  useEffect(() => {
    handleSignIn()
  }, [])

  if (orderAuthCompleted === null) {
    return null
  }

  return orderAuthCompleted ? children : <Navigate to={redirectUrl} replace={true} />
}

export default OrderAuth
